<template>
  <div class="row">
    <in-page-navigation :back-link="'/'" />
  </div>
  <card-block v-html="pageContent" />
</template>

<script setup>
import { ref, onMounted } from 'vue';
import CardBlock from "@/components/CardBlock.vue";
import InPageNavigation from "@/components/nav/InPageNavigation.vue";
import { useApi } from "@/services/api";
import {useUserStore} from "@/store/userStore";

const contentKey = "prize";
const pageContent = ref(null);
const api = ref(null);
const userStore = useUserStore();

const getPageContent = () => {
  api.value.getPageContent(userStore.user.localeFromLatestEnquiry).then((response) => {
    const responseData = response.data;
    let content = responseData.find((page) => page.url === contentKey);
    pageContent.value = content.body;
  });
};

onMounted(() => {
  const { pageApi } = useApi();
  api.value = pageApi;
  getPageContent();
});
</script>
