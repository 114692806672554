<template>
  <button v-if="buttonVisibility" class="btn btn-feedback mt-1 btn-sm" @click="navigate">
    {{ $t('Feedback') }}
  </button>
</template>

<script>
import { computed } from "vue";
import { useFeedbackStore } from "@/store/feedbackStore";
import {useRouter} from "vue-router";

export default {
  name: "EnquiryFeedbackButton",
  props: {
    enquiry: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const feedbackStore = useFeedbackStore();
    feedbackStore.loadForm(props.enquiry.quoteCode);
      const router = useRouter();
      const buttonVisibility = computed(() => {
          const enquiryHasFeedback = feedbackStore.enquiryHasFeedback(props.enquiry.id);
          if (props.enquiry.isReleased === true && enquiryHasFeedback === false && feedbackStore.flow.length >= 1 ) {
              return true;
          }

      return false;
    });
    const navigate = () => {
      router.push({
        name: 'user-feedback',
        params: {
          id: props.enquiry.quoteCode,
        },
      });
    };

    return {buttonVisibility, navigate};
  }
}
</script>
