import {defineStore} from 'pinia';
import {useApi} from "@/services/api";
import {NodeTypes} from '@/services/flow/NodeTypes';
import {captureError} from '@/services/userMonitoring';

const {enquiryApi, formApi} = useApi();
const initialState = {
  enquiry: null,
  formId: null,
  versionName: null,
  flow: [],
  currentNode: null,
  stepsTaken: [],
  answers: {},
  busy: true,
  enquiryFeedbackAnswers: [],
  installers: [],
}

export const useFeedbackStore = defineStore('feedback',{
  persist: true,
  state: () => ({...initialState}),
  getters: {
    currentOutgoingEdges() {
      return this.flow.filter((nodeOrEdge) => {
        const isEdge = nodeOrEdge.target !== undefined && nodeOrEdge.source !== undefined;
        const isConnectedToCurrentNode = nodeOrEdge.source === this.currentNode?.id;
        return isEdge && isConnectedToCurrentNode;
      });
    },
    possibleFutureNodes() {
      return this.currentOutgoingEdges.map((edge) => edge.targetNode);
    },
    previousNode() {
      return this.stepsTaken[this.stepsTaken.length - 1];
    },
  },
    actions: {
        clearStepHistory() {
            this.stepsTaken = [];
          },
          async loadForm(quoteCode, version) {
            this.$reset(initialState);
            Promise.all([
              formApi.getFeedbackFlow(quoteCode, version)
                .then((response) => {
                  this.flow = response.data.data.flow.flow;
                  this.formId = response.data.data.flow.feedbackFlow.id;
                  this.versionName = response.data.data.flow.name;
                  this.currentNode = this.flow.find((node) => node.type === NodeTypes.Start);
                }),
              enquiryApi.fetch(quoteCode)
                .then((response) => {
                  this.enquiry = response.data.data.enquiry;
                  this.installers = response.data.data.installers;
                }),
            ])
            .catch(error => {captureError(error)})
            .finally(() => this.busy = false)
          },
          progressFromHandleId(handleId) {
            const nextNode = this.currentOutgoingEdges.find((edge) => edge.sourceHandle === handleId.toString())?.targetNode ?? null;
            if (nextNode !== null) {
              this.pushNodeToHistory();
              this.currentNode = nextNode;
            }
          },
          progressToNextNode(nodeId) {
            let nextNode = null;
            if (nodeId !== undefined && nodeId !== null) {
              nextNode = this.flow.find((node) => node.id === nodeId);
            } else if (this.possibleFutureNodes.length === 1) {
              nextNode = this.possibleFutureNodes[0];
            }
            this.pushNodeToHistory();
            if (nextNode !== null) {
              this.currentNode = nextNode;
            }
            if (this.shouldSkipNode(this.currentNode)) {
              this.progressToNextNode();
            }
          },
          progressToPreviousNode() {
            const previousNode = this.stepsTaken.pop();
            if (previousNode !== undefined) {
              this.currentNode = previousNode;
            }
          },
          shouldSkipNode(node) {
            switch (node.type) {
              case 'addressConfirmation':
                return false;
            }
            return false;
          },
          async saveAnswer(questionId, answers) {
            const enquiryData = this.getEnquiryData(questionId, answers);
            return enquiryApi.saveFeedbackAnswer(enquiryData)
                .then(() => {
                  this.progressToNextNode()
                })
                .catch((error) => {
                  captureError(error)
              });
          },
          pushNodeToHistory() {
              this.stepsTaken.push(this.currentNode);
          },
          getAnswer(questionid) {
            return this.answers[questionid]
          },
          getEnquiryData(questionId, answers) {
            return {
              enquiryId: answers.enquiryId,
              feedbackFlowId: this.formId,
              feedbackQuestionId: questionId,
              feedbackAnswerId: answers.id ?? 0,
              text: answers.text ?? answers.name,
              leadId: answers.id ?? 0,
            }
          },
          async fetchEnquiryFeedbackAnswers() {
            return enquiryApi.listFeedbackAnswers()
                .then(response => {
                    this.enquiryFeedbackAnswers = [...response.data.data]
                })
                .catch(error => {console.log(error)});
          },
          enquiryHasFeedback(id) {
            const filtered = this.enquiryFeedbackAnswers.filter(efa => efa.enquiry.id === id);
            if (filtered.length > 0) {
                return true;
            }

            return false;
          },
    }
});
