export const NodeTypes = {
    Start: 'start',
    WorkTypeQuestion: 'workTypeQuestion',
    Qualify: 'qualifyEnquiry',
    AddressConfirmation: 'addressConfirmation',
    DynamicQuestion: 'dynamicQuestion',
    PhoneConfirmation: 'phoneConfirmation',
    InvalidateEnquiry: 'invalidation',
    RejectMessage: 'reject',
    SuccessMessage: 'successful',
    ReviewDetails: 'reviewDetails',
    PhoneVerification: 'phoneVerification',
    CompanySelection: 'companySelection',
    FeedbackEnd: 'end',
    FeedbackQuestion: 'feedbackQuestion',
}
